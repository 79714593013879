<template>
  <div class="content">
    <div class="banner"><h1>Mentions légales</h1></div>

    <h2>Droits</h2>
    En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
    confiance dans l’économie numérique, il est précisé aux utilisateurs du site
    Cors'pertu l’identité des différents intervenants dans le cadre de sa
    réalisation et de son suivi : <br />
    Cors'pertu est une association de loi 1901 déclarée à la préfecture de
    Haute-Corse le 20/04/2021. <br />
    Le siège est situé Résidence Les Cimes Bat. 4, route inférieure de Cardo,
    20200 Bastia <br />
    Téléphone: 06.20.59.02.94 <br />
    Le site est hébergé par Gandi SAS, au capital de 630 000€, immatriculée au RCS de Paris
    sous le numéro 423 093 459 dont le siège social se trouve 63-65 bd Masséna Paris 75013 <br />

    Responsable de publication: Arnaud Bascou

    <h2>Services fournis</h2>

    Le site Cors'pertu a pour objet de fournir une information concernant
    l’ensemble des activités de l'association'. Arnaud Bascou s’efforce de
    fournir sur le site Cors'pertu des informations aussi précises que possible.
    Toutefois, il ne pourra être tenu responsable des omissions, des
    inexactitudes et des carences dans la mise à jour, qu’elles soient de son
    fait ou du fait des tiers partenaires qui lui fournissent ces informations.
    Tous les informations indiquées sur le site Cors'pertu sont données à titre
    indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements
    figurant sur le site Cors'pertu ne sont pas exhaustifs. Ils sont donnés sous
    réserve de modifications ayant été apportées depuis leur mise en ligne.

    <h2>Limitations contractuelles sur les données techniques</h2>

    Le site utilise la technologie JavaScript (Vue.JS). <br />
    Le site Internet ne pourra être tenu responsable de dommages matériels liés
    à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder
    au site en utilisant un matériel récent, ne contenant pas de virus et avec
    un navigateur de dernière génération mis-à-jour.

    <h2>Propriété intellectuelle</h2>

    Arnaud Bascou est propriétaire des droits de propriété intellectuelle ou
    détient les droits d’usage sur tous les éléments accessibles sur le site,
    notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
    Toute reproduction, représentation, modification, publication, adaptation de
    tout ou partie des éléments du site, quel que soit le moyen ou le procédé
    utilisé, est interdite, sauf autorisation écrite préalable de : Arnaud
    Bascou. Toute exploitation non autorisée du site ou de l’un quelconque des
    éléments qu’il contient sera considérée comme constitutive d’une contrefaçon
    et poursuivie conformément aux dispositions des articles L.335-2 et suivants
    du Code de Propriété Intellectuelle.

    <h2>Limitation de responsabilité</h2>

    Arnaud Bascou ne pourra être tenu responsable des dommages directs et
    indirects causés au matériel de l’utilisateur, lors de l’accès au site
    Cors'pertu, et résultant soit de l’utilisation d’un matériel ne répondant
    pas aux spécifications indiquées au point sur les limitations
    contractuelles, soit de l’apparition d’un bug ou d’une incompatibilité.
    Arnaud Bascou ne pourra également être tenue responsable des dommages
    indirects (tels par exemple qu’une perte de marché ou perte d’une chance)
    consécutifs à l’utilisation du site Cors'pertu.

    <h2>Données personnelles</h2>

    En France, les données personnelles sont notamment protégées par la loi n°
    78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L.
    226-13 du Code pénal et la Directive Européenne du 24 octobre 1995. <br>
     A
    l’occasion de l’utilisation du site Cors'pertu, peuvent être
    recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a
    accédé au site Cors'pertu, le fournisseur d’accès de l’utilisateur,
    l’adresse de protocole Internet (IP) de l’utilisateur. <br>
     En tout état de cause
    Arnaud Bascou ne collecte des informations personnelles relatives à
    l’utilisateur que pour le besoin de certains services proposés par le site
    Cors'pertu. L’utilisateur fournit ces informations en toute
    connaissance de cause, notamment lorsqu’il procède par lui-même à leur
    saisie. Il est alors précisé à l’utilisateur du site Cors'pertu
    l’obligation ou non de fournir ces informations. <br>
     Conformément aux
    dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978
    relative à l’informatique, aux fichiers et aux libertés, tout utilisateur
    dispose d’un droit d’accès, de rectification et d’opposition aux données
    personnelles le concernant, en effectuant sa demande écrite et signée,
    accompagnée d’une copie du titre d’identité avec signature du titulaire de
    la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée. <br>

    Aucune information personnelle de l’utilisateur du site Cors'pertu
    n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou
    vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de
    Cédric David LAHMI et de ses droits permettrait la transmission des dites
    informations à l’éventuel acquéreur qui serait à son tour tenu de la même
    obligation de conservation et de modification des données vis à vis de
    l’utilisateur du site Cors'pertu. <br>
     Le site n’est pas déclaré à la CNIL
    car il n’exploite pas les données personnelles au sens de la CNIL <br>

    Les bases de données sont protégées par les dispositions de la loi du 1er
    juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la
    protection juridique des bases de données.

    <h2>Crédits Photo</h2>
    <ul>
      <li>Photos libres de droits</li>
      <li>Photos sous licence Creative Commons permettant l'usage et la modification</li>
      <li>Photos personnelles du propriétaire du site</li>
    </ul>

    <h2>Conditions générales d'utilisation</h2>
    L’utilisation du site Cors'pertu implique l’acceptation pleine et entière
    des conditions générales d’utilisation ci-après décrites. Ces conditions
    d’utilisation sont susceptibles d’être modifiées ou complétées à tout
    moment, les utilisateurs du site Cors'pertu sont donc invités à les
    consulter de manière régulière. Ce site est normalement accessible à tout
    moment aux utilisateurs. Une interruption pour raison de maintenance
    technique peut être toutefois décidée par l'hébergeur, qui s’efforcera alors
    de communiquer préalablement aux utilisateurs les dates et heures de
    l’intervention. Le site Cors'pertu est mis à jour régulièrement par Arnaud
    Bascou. De la même façon, les mentions légales peuvent être modifiées à tout
    moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y
    référer le plus souvent possible afin d’en prendre connaissance.

    <h2>Conditions générales de vente</h2>
    Les CGV sont remises lors de l'établissement du devis.
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style>
.legal {
  
  margin-left: 20%;
  margin-right: 20%;
}
</style>